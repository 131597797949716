import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { debounce } from 'lodash';
import { Text } from 'wix-ui-tpa/cssVars';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { st, classes } from '../../Widget.st.css';
import SelectedLayout from './SelectedLayout';
import { FAQService } from '../../../../../utils/FAQService';
import { usePaginatedQuestions } from '../../../../../hooks/usePaginatedQuestions';

const QuestionsWrapper = ({ questions }: { questions: QuestionEntry[] }) => {
  const { selectedCategory, searchTerm, instance } = useContext(AppContext);
  const { t } = useTranslation();
  const api = useMemo(() => new FAQService(instance), [instance]);

  const [questionsList, setQuestionsList] = useState<QuestionEntry[]>(
    questions || [],
  );
  useEffect(() => {
    setQuestionsList(questions);
  }, [questions]);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const firstLoad = useRef(true);

  const { searchResultsState, setSearchResultsState } = usePaginatedQuestions({
    api,
    selectedCategory,
    setQuestionsList,
    bottomRef,
    searchTerm,
  });

  const fetchQuestions = useCallback(
    async (term?: string) => {
      setSearchResultsState((prev) => ({ ...prev, loading: true }));
      try {
        const response = term
          ? await api.getSearchTerm({ term })
          : await api.getListQuestions({ categoryId: selectedCategory });
        setQuestionsList(response.questionEntries || []);
        setSearchResultsState({
          cursor: response.pagingMetadata?.cursors?.next || null,
          loading: false,
          hasMore: response.pagingMetadata?.hasNext || false,
        });
      } catch (error) {
        console.error('Error fetching questions:', error);
        setSearchResultsState((prev) => ({ ...prev, loading: false }));
      }
    },
    [api, selectedCategory, setSearchResultsState],
  );

  const debouncedSearch = useMemo(
    () => debounce(fetchQuestions, 400),
    [fetchQuestions],
  );

  useEffect(() => {
    if (!firstLoad.current) {
      debouncedSearch(searchTerm);
    }
    firstLoad.current = false;
  }, [searchTerm, debouncedSearch]);

  const loadMoreQuestions = useCallback(async () => {
    if (
      !searchResultsState.cursor ||
      searchResultsState.loading ||
      !searchResultsState.hasMore
    ) {
      return;
    }

    setSearchResultsState((prev) => ({ ...prev, loading: true }));
    try {
      const response = await api.getSearchTerm({
        term: searchTerm,
        cursor: searchResultsState.cursor,
      });
      setQuestionsList((prev) => [
        ...prev,
        ...(response.questionEntries || []),
      ]);
      setSearchResultsState({
        cursor: response.pagingMetadata?.cursors?.next || null,
        hasMore: response.pagingMetadata?.hasNext || false,
        loading: false,
      });
    } catch (error) {
      console.error('Error loading more questions:', error);
      setSearchResultsState((prev) => ({ ...prev, loading: false }));
    }
  }, [api, searchResultsState, searchTerm, setSearchResultsState]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          searchResultsState.hasMore &&
          !searchResultsState.loading
        ) {
          loadMoreQuestions();
        }
      },
      { rootMargin: `${window.innerHeight * 0.6}px` },
    );

    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }
    return () => observer.disconnect();
  }, [
    searchResultsState.hasMore,
    searchResultsState.loading,
    loadMoreQuestions,
  ]);

  return (
    <>
      {questionsList.length > 0 ? (
        <div className={st(classes.Body, { resultFound: !!searchTerm })}>
          {searchTerm && (
            <div className={st(classes.resultFound)}>
              <Text>
                {t('widget.search.tab.results.found', { searchTerm })}
              </Text>
            </div>
          )}
          <SelectedLayout filteredQuestions={questionsList} />
        </div>
      ) : (
        <div className={st(classes.Body, { noResult: true })}>
          <Text className={st(classes.noResultText)}>
            <Trans
              i18nKey={
                questionsList.length > 0
                  ? 'widget.search.tab.no.search.results.found'
                  : 'widget.search.tab.no.results.found'
              }
              values={{
                tryAgain:
                  questionsList.length > 0
                    ? t('widget.search.tab.no.results.found.try.again')
                    : t('widget.search.tab.no.search.results.found.try.again'),
              }}
            >
              <div></div>
            </Trans>
          </Text>
        </div>
      )}
      <div ref={bottomRef} style={{ height: 0, width: 0 }} />
    </>
  );
};

export default QuestionsWrapper;

import { HttpClient, HttpResponse } from '@wix/yoshi-flow-editor';
import { queryQuestionEntries } from '@wix/ambassador-faq-question-entry-v2-question-entry/http';
import { listCategories } from '@wix/ambassador-faq-category-v2-category/http';
import type { ListCategoriesResponse } from '@wix/ambassador-faq-category-v2-category/types';
import {
  QueryQuestionEntriesRequest,
  SortOrder,
} from '@wix/ambassador-faq-question-entry-v2-question-entry/types';

export class FAQService {
  faqService: HttpClient;
  constructor(instance: string = '', useBaseURL = false) {
    this.faqService = new HttpClient({
      baseURL: useBaseURL ? 'https://editor.wixapps.net/faq' : undefined,
      headers: {
        Authorization: instance,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getListCategories = async () => {
    const response: HttpResponse<ListCategoriesResponse> =
      await this.faqService.request(listCategories({}));

    while (response.data.pagingMetadata?.hasNext) {
      const nextCursor = response.data.pagingMetadata.cursors?.next;

      const nextResponse = await this.faqService.request(
        listCategories({
          paging: nextCursor ? { cursor: nextCursor } : undefined,
        }),
      );

      response.data.categories!.push(...nextResponse.data.categories!);
      response.data.pagingMetadata = nextResponse.data.pagingMetadata;
    }

    return response.data;
  };

  getListQuestions = async ({
    categoryId,
    cursor,
  }: {
    categoryId?: string | null | undefined | (string | null | undefined)[];
    limit?: number;
    cursor?: string;
  }) => {
    const requestPayload: QueryQuestionEntriesRequest = {
      query: {
        cursorPaging: { cursor },
      },
    };

    if (!cursor && requestPayload.query) {
      requestPayload.query.filter = { categoryId };
      requestPayload.query.sort = [
        { fieldName: 'sortOrder', order: SortOrder.ASC },
      ];
    }

    const response = await this.faqService.request(
      queryQuestionEntries(requestPayload),
    );
    return response.data;
  };

  getQuestion = async ({
    id,
  }: {
    id?: string | null | undefined | (string | null | undefined)[];
  }) => {
    const response = await this.faqService.request(
      queryQuestionEntries({
        query: {
          filter: { id },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    );
    return response.data;
  };

  getSearchTerm = async ({
    term,
    cursor,
  }: {
    term: string;
    cursor?: string;
  }) => {
    term = '%' + `${term}`.trim().replaceAll(' ', '%');

    let requestPayload: QueryQuestionEntriesRequest;

    if (!cursor) {
      // on initial load
      requestPayload = {
        query: {
          filter: {
            $or: [
              {
                draftjs: {
                  $startsWith: term,
                },
              },
              {
                question: {
                  $startsWith: term,
                },
              },
            ],
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      };
    } else {
      // on load more
      requestPayload = {
        query: {
          cursorPaging: { cursor },
        },
      };
    }

    const response = await this.faqService.request(
      queryQuestionEntries(requestPayload),
    );

    return {
      questionEntries: response.data?.questionEntries || [],
      pagingMetadata: response.data?.pagingMetadata || {},
    };
  };
}
